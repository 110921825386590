<template>
  <main>
    <section class="realisations" id="realizacie">
      <div class="title">
        <h1>Naše práce</h1>
      </div>
    </section>
    <section class="gridgallery mt-68">
      <ZulaGallery/>
      <JednopomnikGallery/>
      <DvojpomnikGallery/>
      <NamieruGallery/>
      <DomacnostGallery/>
    </section>
  </main>
</template>
<script>
import ZulaGallery from '../components/ZulaGallery'
import JednopomnikGallery from '../components/JednopomnikGallery'
import DvojpomnikGallery from '../components/DvojpomnikGallery'
import NamieruGallery from '../components/NamieruGallery'
import DomacnostGallery from '../components/DomacnostGallery'
export default {
	name: 'Realisations',
	components:{
		 ZulaGallery, JednopomnikGallery, DvojpomnikGallery, NamieruGallery, DomacnostGallery
	},
}
</script>


<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";
@import "../assets/scss/_gridgallery";

.realisations{
  @include overlay;
  @include views-title;
}
.gridgallery{
  .section-header{
    h2{
      @include section-header;
      margin-bottom: 24px !important;
    }
    a{
      @include buttons;
    }
  }
}




</style>
