<template>
  <div>
    <div class="container-fluid" id="dvojpomnikgallery">
      <div class="row">
        <div class="col-lg-12">
          <section class="section-header mt-34">
            <h2>Dvojpomníky</h2>
            <router-link to="/produkty/dvojpomniky/#dvojpomniky">Viac informácií</router-link>
          </section>
        </div>
      </div>
    </div>
    <div class="gridgallery-img">
      <div class="gridgallery-content" @click="openGallery(index)" v-for="(img, index) in allDvojpomnikImg" :key="index.id">
        <div class="gridgallery-overlay" ></div>
        <img :src="img.src" title="Dvojpomníky"/>
        <div class="gridgallery-details">
          <p>Ukážka</p>
        </div>
      </div>
    <LightBox
        ref="lightbox"
        :media="allDvojpomnikImg"
        :show-caption="true"
        :show-light-box="false"
    />
  </div>
</div>
  
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import LightBox from 'vue-it-bigger'
const media = []
export default {
	name: 'DvojpomnikGallery',
	components:{
		LightBox, 
	},
	data(){
		return{
			media,
		}
	},
	computed:{
    	...mapGetters(['allDvojpomnikImg']),
  	},
	created (){
		if (this.getDvojpomnikImg.length < 1) {
			this.getDvojpomnikImg()
		}
	},
	methods: {
    	...mapActions(['getDvojpomnikImg']),
		openGallery(index) {
			this.$refs.lightbox.showImage(index)
		}
  }
}
</script>

<style>

</style>